'use strict'
angular.module('cb.time-sheet', [])
	.controller('TimeSheetController', ["$scope", "$rootScope", "$modal", "UserService", "NotificationService", function ($scope, $rootScope, $modal, UserService, NotificationService) {
		$scope.init = function () {
			$scope.User = $rootScope.User;
			$scope.Paginationinfo = {}
			$scope.pagination = {};
			$scope.PageNumber = 1;
			$scope.AllowFilter = false;
			$scope.FilterYear = moment().year();
			$scope.FetchTimeSheets();
		};

		$scope.WeekChange = function () {
			if ($scope.FilterWeek.toString().length >= 1)
				$scope.FetchTimeSheets();
		}

		$scope.YearChange = function () {
			if ($scope.FilterYear.toString().length == 4)
				$scope.FetchTimeSheets();
		}

		$scope.$watch('PageNumber', function (n, o) {
			if ($scope.PageNumber != undefined)
				$scope.FetchTimeSheets();
		});

		$scope.FetchTimeSheets = function () {
			var WeekFilter = 0;
			var YearFilter = 0;
			if ($scope.AllowFilter == true) {
				if ($scope.FilterWeek.toString().length >= 1)
					WeekFilter = $scope.FilterWeek;
				if ($scope.FilterYear.toString().length == 4)
					YearFilter = $scope.FilterYear;
			}
			var data = {
				userId: $scope.User.Id,
				Pagination: {
					StartPage: $scope.PageNumber -1,
					PageSize: 20,
				},
				WeekFilter: WeekFilter,
				YearFilter: YearFilter
			};
			$scope.FetchTimeSheetsPromise = UserService.FetchTimeSheets(data);
			$scope.FetchTimeSheetsPromise.then(function (output) {
				$scope.TimeSheets = output.data.TimeSheetOutput;
				$scope.LastMileage = output.data.LastMileage;
				$scope.pagination = output.data.pagination;
			})
		}

		$scope.CreateTimeSheet = function () {
			$scope.openModal('CreateTimeSheetController', 'CreateTimeSheet.html', $scope.LastMileage);
		}

		$scope.EditTimeSheet = function (Id) {
			$scope.openModal('EditTimeSheetController', 'EditTimeSheet.html', { Id: Id, LastMileage: $scope.LastMileage });
		}

		$scope.DeleteTimeSheet = function (Id) {
			NotificationService.confirm("Warning", "Are you sure that you want to delete this Time Sheet?", {}).get().on('pnotify.confirm', function () {
				$scope.DeleteTimeSheetPromise = UserService.DeleteTimeSheet(Id);
				$scope.DeleteTimeSheetPromise.then(function (output) {
					NotificationService.alert("Time Sheet Deleted", "The Time Sheet was successfully deleted.", "success", {});
					$scope.TimeSheets = [];
					$scope.FetchTimeSheets();
				});
			});
		}

		$scope.openModal = function (controller, template, data) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: template,
				controller: controller,
				resolve: {
					Data: function () {
						return data;
					}
				}
			});
			modalInstance.result.then(function (output) {
				if (output.Moveto == 'Next') {
					var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
					$scope.next = ($scope.Duplicates[Currentindex + 1] ? $scope.Duplicates[Currentindex + 1].Id : null);

				} else if (output.Moveto == 'Back') {
					var Currentindex = $scope.Duplicates.findIndex(a => a.Id == output.CurrentId);
					$scope.next = ($scope.Duplicates[Currentindex - 1] ? $scope.Duplicates[Currentindex - 1].Id : null);
				}
			})
			modalInstance.result.finally(function () {
				if ($scope.next != null) {
					$scope.openDeduplication($scope.next)
					$scope.next = null;
				}
				$scope.init();
			});
		};
	}])

	.controller('CreateTimeSheetController', ["$scope", "Data", "$rootScope", "UserService", "NotificationService", "$modalInstance", function ($scope,Data, $rootScope, UserService, NotificationService, $modalInstance) {
		$scope.init = function () {
			$scope.UserId = $rootScope.User.Id;
			$scope.LastMileage = Data;
			$scope.TimeSheet = {};
			$scope.openPopups = [];
			$scope.AddCustomEntry = {};
			$scope.CustomEntry = [];
			$scope.TimeSheetApps = [];
			$scope.hide = false;
			$scope.SaveInProgress = false;
			$scope.PopulateTimeSheet();
			$scope.HideCustomEntry = false;
		}

		$scope.PopulateTimeSheet = function () {
			$scope.TimeSheet.TimeSheetDate = new moment($scope.TimeSheet.EntryDate);
			var data = {
				UserId: $scope.UserId,
				Date: $scope.TimeSheet.TimeSheetDate
			}
			$scope.FetchAppointmentsForTimeSheetsPromise = UserService.PopulateTimeSheet(data)
			$scope.FetchAppointmentsForTimeSheetsPromise.then(function (output) {
				$scope.TimeSheetApps = output.data.AppointmentList; 
				$scope.NumberOfRemovedAppointments = 0;
				if ($scope.TimeSheet.EntryDate != undefined) {
					$scope.hide = true;
					$scope.TimeSheet.Week = moment($scope.TimeSheet.EntryDate).week();
				}
			});
		}


		$scope.CalculateTodayMileage = function () {
			var miles = $scope.TimeSheet.VehicleMileage - $scope.LastMileage;
			if (miles > 0)
				$scope.TimeSheet.Mileage = miles;
			else
				$scope.TimeSheet.Mileage = 0;
		}

		$scope.RemoveAppointment = function (Appointment) {
			Appointment.Remove = true;
			$scope.NumberOfRemovedAppointments++;
			NotificationService.alert('Appointment Removed', 'The appointment has been removed from the time sheet.', 'success', []);
		}

		$scope.$watchCollection('[TimeSheet.EntryDate]', function () {
			$scope.TimeSheetApps = [];
			$scope.PopulateTimeSheet();
		});

		$scope.AddCustomEntryToCustomEntries = function () {
			$scope.AddCustomEntry.Index = $scope.CustomEntry.length + 1;
			$scope.CustomEntry.push($scope.AddCustomEntry);
			$scope.AddCustomEntry = {};
		};

		$scope.RemoveCustomEntry = function () {
			$scope.CustomEntry.pop();
		}

		$scope.Save = function () {
			$scope.SaveInProgress = true;
			$scope.TimeSheet.TimeSheetDate = new moment($scope.TimeSheet.EntryDate);
			$scope.SaveNewTimeSheetPromise = UserService.CreateTimeSheet($scope.TimeSheet);
			$scope.SaveNewTimeSheetPromise.then(function (output) {
				var TimeSheetId = output.data;
				NotificationService.alert('New Time Sheet Added', 'A new Time Sheet has been created.', 'success', []);
				//handle appointments related to time sheet.
				if ($scope.TimeSheetApps.length > 0) {
					var data = {
						AppointmentList: $scope.TimeSheetApps,
						TimeSheetId: TimeSheetId,
					};
					$scope.SaveTimeSheetAppsPromise = UserService.CreateTimeSheetApps(data);
					$scope.SaveTimeSheetAppsPromise.then(function () {
						NotificationService.alert('Appointment Entry Created', 'Time Sheet Appointments added successfully.', 'success', []);
					});
				}
				//Custom Time Sheet Entries
				if ($scope.CustomEntry.length > 0) {
					var CustomData = {
						TimeSheetId: TimeSheetId,
						CustomApp: $scope.CustomEntry
					};
					$scope.SaveCustomTimeSheetAppsPromise = UserService.CreateCustomTimeSheetApps(CustomData);
					$scope.SaveCustomTimeSheetAppsPromise.then(function () {
						NotificationService.alert('Custom Entry Created', 'Custom Time Sheet Appointments added successfully.', 'success', []);
					});
				}
				$scope.Close();
			});
		}

		$scope.goToLead = function (ref, appointmentType) {
			if (appointmentType == 'Enquiry')
				window.open('/#/lead-details/' + ref);
			else
				window.open('/#/sales-order/' + ref);
		}

		$scope.Close = function () {
			$modalInstance.dismiss();
		}

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};
	}])

	.controller('EditTimeSheetController', ["$scope", "Data", "$rootScope", "UserService", "NotificationService", "$modalInstance", function ($scope, Data, $rootScope, UserService, NotificationService, $modalInstance) {
		var mileage = 0;
		$scope.init = function () {
			$scope.UserId = $rootScope.User.Id
			$scope.TimeSheetId = Data.Id;
			$scope.LastMileage = Data.LastMileage;
			$scope.openPopups = [];
			$scope.TimeSheetApps = [];
			$scope.AddCustomEntry = {};
			$scope.CustomEntry = [];
			$scope.FetchTimeSheet();
			$scope.FetchTimeSheetAppointments();
		}

		$scope.CalculateTodayMileage = function () {
			var startingMileage = Data.LastMileage - mileage;
			var miles = $scope.TimeSheet.VehicleMileage - startingMileage;
			//var miles = $scope.TimeSheet.VehicleMileage - $scope.LastMileage;
			if (miles > 0)
				$scope.TimeSheet.Mileage = miles;
			else
				$scope.TimeSheet.Mileage = 0;
		}

		$scope.FetchTimeSheet = function () {
			$scope.FetchTimeSheetByIdPromise = UserService.FetchTimeSheetById($scope.TimeSheetId);
			$scope.FetchTimeSheetByIdPromise.then(function (output) {
				mileage = output.data.TimeSheet.Mileage;
				$scope.TimeSheet = output.data.TimeSheet;
				$scope.CustomEntry = output.data.CustomEntry;
				$scope.TimeSheet.EntryDate = moment($scope.TimeSheet.EntryDate).format("DD/MM/YYYY");
			});
		}

		$scope.FetchTimeSheetAppointments = function () {
			$scope.FetchTimeSheetAppointmentsByIdPromise = UserService.FetchTimeSheetAppointmentsById($scope.TimeSheetId);
			$scope.FetchTimeSheetAppointmentsByIdPromise.then(function (output) {
				$scope.TimeSheetApps = output.data.AppointmentList;
			});
		}

		$scope.AddCustomEntryToCustomEntries = function () {
			$scope.AddCustomEntry.Index = $scope.CustomEntry.length + 1;
			$scope.AddCustomEntry.Id = 0;
			$scope.CustomEntry.push($scope.AddCustomEntry);
			$scope.AddCustomEntry = {};
		};
		
		$scope.RemoveCustomEntry = function () {
			$scope.CustomEntry.pop();
		}

		$scope.RemoveExistingCustomEntry = function (Id) {
			console.log(Id);
			if (confirm("Are you sure you want to delete this custom entry? This cannot be reverted.")) {
				$scope.RemoveExistingCustomEntryPromise = UserService.RemoveCustomTimeSheetEntry(Id)
				$scope.RemoveExistingCustomEntryPromise.then(function (output) {
					if (output.data == "true") {
						NotificationService.alert("Updated Time Sheet", "The custom entry has been removed. Please update the rest of the timesheet to reflect any changes", "success", {});
						$scope.FetchTimeSheet();
					}
					else
						NotificationService.alert("Updated Time Sheet Failed", "There was an error removing the custom entry. Please try again later.", "error", {});
				});
			}
		}

		$scope.Update = function () {
			var data = {
				TimeSheetId: $scope.TimeSheetId,
				Mileage: $scope.TimeSheet.Mileage,
				TimeSpent: $scope.TimeSheet.TimeSpent,
				Notes: $scope.TimeSheet.Notes,
				VehicleMileage: $scope.TimeSheet.VehicleMileage,
			}
			$scope.EditTimeSheetPromise = UserService.EditTimeSheet(data);
			$scope.EditTimeSheetPromise.then(function (output) {
				if ($scope.TimeSheetApps.length > 0 || $scope.CustomEntry.length > 0) {
					var data2 = {
						TimeSheetId: $scope.TimeSheet.Id,
						AppointmentList: $scope.TimeSheetApps,
						CustomEntryList: $scope.CustomEntry
					}
					$scope.EditTimeSheetAppointmentsPromise = UserService.EditTimeSheetApps(data2);
					$scope.EditTimeSheetAppointmentsPromise.then(function () {
						NotificationService.alert("Updated Time Sheet", "This time sheet has been successfully updated.", "success", {});
						$scope.Close();
					});
				}
				else {
					NotificationService.alert("Updated Time Sheet", "This time sheet has been successfully updated.", "success", {});
					$scope.Close();
				}
					
			});
		}

		$scope.goToLead = function (ref, appointmentType) {
			if (appointmentType == 'Enquiry')
				window.open('/#/lead-details/' + ref);
			else
				window.open('/#/sales-order/' + ref);
		}

		$scope.Close = function () {
			$modalInstance.dismiss();
		}
	}])
